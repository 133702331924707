import React from "react";
import { Layout, Stack, Main } from "@layout";
import PageTitle from "@components/PageTitle";
import Divider from "@components/Divider";
import Seo from "@widgets/Seo";
import "./pages.css";

const About = (props) => {
  return (
    <Layout {...props}>
      <Seo title="About 3air" />
      <Divider />
      <Stack effectProps={{ effect: "fadeInDown" }}>
        <PageTitle header="About 3air" />
      </Stack>
      <Stack>
        <Main>
          <h1>3air: Blockchain and banking for the next billion</h1>
          <p>3air brings African users online and into the crypto and blockchain space. Initially, 3air solves the connectivity issues faced on the world’s second-largest continent by population, using a proprietary solution that provides high-speed broadband internet wirelessly. 3air will provide users with a reliable connection to the outside world and ensure they’re included in the global educational, health, financial, and business economies. Imagine what this means for the unconnected?</p>
          <p>The 3air platform gives users the tools to transact and earn an income: online payments, DeFi, NFT ownership, Learn-to-Earn & Play-to-Earn modules, staking & token rewards and more. 3air’s goal is to educate and empower all users to use blockchain to grow and manage their wealth.</p>
          <p>For more information on how you can get involved, follow us on social media or join us on Telegram or Discord!</p>
          <ul>
            <li>Twitter 👉 <a href="https://twitter.com/3aircrypto/" target="_blank" rel="noopener noreferrer">3aircrypto</a></li>
            <li>Instagram 👉 <a href="https://www.instagram.com/3air.crypto/" target="_blank" rel="noopener noreferrer">3air.crypto</a></li>
            <li>Facebook 👉 <a href="https://www.facebook.com/3aircrypto/" target="_blank" rel="noopener noreferrer">3aircrypto</a></li>
            <li>Telegram 👉 <a href="https://t.me/my3air/" target="_blank" rel="noopener noreferrer">my3air</a></li>
            <li>Discord 👉 <a href="https://discord.com/invite/3air/" target="_blank" rel="noopener noreferrer">discord.com/invite/3air/</a></li>
            <li>YouTube 👉 <a href="https://www.youtube.com/c/3aircrypto" target="_blank" rel="noopener noreferrer">3aircrypto</a></li>
          </ul>
        </Main>
      </Stack>
    </Layout>
  );
};

export default About;
